<template>
  <div class="prizecon">
    <div class="title">
      <h5 class="blod">{{ $t('vip.your_rewards') }}</h5>
      <p>{{ $t('vip.prize_detail') }}</p>
    </div>
    <!-- 我的奖品 -->
    <div class="interList">
      <div class="name">
        <span>{{ $t('vip.rewards') }}</span>
        <span>{{ $t('vip.date') }}</span>
        <span>{{ $t('vip.rewards_code') }}</span>
        <span>{{ $t('vip.effective_time') }}</span>
      </div>
      <div class="list">
        <div class="l_item" v-for="(item, i) in notused" :key="i">
          <div>{{ $i18n.locale == "en" ? item.coupontitleen:item.coupontitle }}<span v-if="item.channel==3">({{ $t('vip.no_goods') }})</span></div>
          <div>{{ item.adddate }}</div>
          <div>
            <span v-if="item.couponcode">
              {{ item.couponcode }}
              <span id="copy" class="copy" :data-clipboard-text="item.couponcode" @click="copyUrl" v-if="item.channel!=3">{{ $t('vip.copy') }}</span>
            </span>
            <span v-else>{{ $t('vip.no_code') }}</span>
          </div>
          <div>{{ item.overduedate }}</div>
        </div>
        <div class="l_item use" v-show="useShow" v-for="(item, i) in used" :key="i">
          <div>{{ $i18n.locale == "en" ? item.coupontitleen:item.coupontitle }}({{ $t('vip.used') }})</div>
          <div>{{ item.adddate }}</div>
          <div>{{ item.couponcode }}</div>
          <div>{{ item.overduedate }}</div>
        </div>
        <q-btn v-if="used.length" dense flat :label="useShow ? $t('vip.hide') : $t('vip.look_use_prize')" icon="card_giftcard" @click="useShow = !useShow" />
      </div>
    </div>
    <m-empty v-if="notused.length == 0" :title="$t('vip.no_prize')"></m-empty>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { copyHandle } from '@/utils/common'
import { getPrize } from '@/api/my'
export default {
  components: {
    mEmpty
  },
  data () {
    return {
      useShow: false,
      notused: [],
      used: []
    }
  },
  methods: {
    async getMyPrize () {
      const res = await getPrize({
        action: 'user_VIPdiscountlist'
      })
      this.notused = res.notused
      this.used = res.used
    },
    copyUrl () {
      copyHandle('#copy')
    }
  },
  mounted () {
    this.getMyPrize()
  }
}
</script>

<style lang="scss" scoped>
.prizecon {
  .title {
    text-align: center;
    margin-bottom: 50px;
    h5 {
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 0;
    }
    p {
      font-size: 12px;
    }
  }
}
.interList {
  max-width: 1060px;
  .name {
    display: flex;
    border-bottom: 1px solid #ccc;
    span {
      display: inline-block;
      width: 20%;
      margin-bottom: 5px;
    }
    & > span:nth-of-type(1) {
      flex: 1;
    }
  }
  .list {
    .l_item {
      display: flex;
      border-bottom: 1px solid #ccc;
      padding: 16px 0px;
      &.use {
        background-color: rgba(0, 0, 0, 0.05);
        color: #a8a8a8;
      }
      & > div {
        width: 20%;
        font-size: 14px;
      }
      & > div:nth-of-type(1) {
        flex: 1;
        padding-left: 15px;
      }
      .copy {
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
}
</style>
